* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

span.header {
  font-family: "Quicksand", serif;
  position: absolute;
  display: inline-block;
  width: 800px;
  top: 50%;
  transform: translate3d(10%, -60%, 0%);
  left: 0.3em;
  font-size: 12em;
  line-height: 1em;
}

@media only screen and (max-width: 750px) {
  span.header {
    top: 250px;
    left: 50px;
    font-size: 6em;
    width: 200px;
  }
  a.bottom-left {
    display: none;
  }
}

a {
  color: #333;
  font-family: "Quicksand", sans-serif;
  position: absolute;
  font-size: 1.1em;
  display: inline;
  text-decoration: none;
}

a.top-left {
  top: 50px;
  left: 50px;
}

a.top-right {
  top: 50px;
  right: 50px;
}

a.bottom-left {
  bottom: 50px;
  left: 50px;
}

a.bottom-right {
  bottom: 50px;
  right: 50px;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  overflow: hidden;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid .item {
  position: relative;
  width: 50vw;
  height: 50vw;
  background: #eee;
}

@media only screen and (max-width: 480px) {
  .grid .item {
    width: 100vw;
    height: 100vw;
  }
}

.scroll-container {
  position: absolute;
  overflow: auto;
  top: 0px;
  width: 100%;
  height: 100vh;
  font-size: 20em;
  font-weight: 800;
  line-height: 0.9em;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  color: black;
  background: white;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Quicksand", sans-serif;
  color: black;
}
